.tick line {
  color: #d7d7d7;
}

.tick text {
  color: #574e75;
}

path.domain {
  color: #d7d7d7;
}
